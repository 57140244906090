import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxCheckBox = _resolveComponent("DxCheckBox")!
  const _component_CreateEditDialog = _resolveComponent("CreateEditDialog")!

  return (_openBlock(), _createBlock(_component_CreateEditDialog, {
    saveModelCallback: _ctx.saveModelCallback,
    ref: "dialog"
  }, {
    editForm: _withCtx(({ model }) => [
      _createVNode(_component_DxCheckBox, {
        value: model.use,
        "onUpdate:value": ($event: any) => ((model.use) = $event)
      }, null, 8, ["value", "onUpdate:value"])
    ]),
    _: 1
  }, 8, ["saveModelCallback"]))
}