
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import { DxCheckBox } from "devextreme-vue/check-box";

export default defineComponent({
  components: {
    CreateEditDialog,
    DxCheckBox,
  },
  props: {
    saveModelCallback: { required: true },
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
  },
  data() {
    return {};
  },
});
